<template>
  <app-image v-bind="logoBinding" />
</template>

<script lang="ts" setup>
const properties = defineProps<{
  light?: boolean;
}>();

const logoBinding = computed(() => {
  return {
    alt: "Pollen logo",
    class: "w-[125px] desktop-nav:w-[170px]",
    width: 170,
    src: properties.light
      ? "/images/logo_full_white.svg"
      : "/images/logo_full_color.svg",
  };
});
</script>
